import React, { useState, useEffect, useRef } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { FormControl, InputLabel, Select, MenuItem, Button, createTheme, ThemeProvider, Tooltip } from '@mui/material';
import { getMinAndMaxDate, getColumnsUniqueValues, filterProjectProductViewData } from './api';
import { useParams } from "react-router-dom";

import { saveAs } from 'file-saver';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

import LinePlot from './LinePlot';
import KpiElements from './KpiElement';

export const inSaleOptions = ['All', 'Yes', 'No'];

const theme = createTheme({
  palette: {
    primary: {
      main: '#000000', // Black color
    },
  },
});


const OnExportClick = ({ data }) => {
  const handleExportClick = () => {
    const csvData = prepareCSVData(data);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, 'exported_data.csv');
  };

  const prepareCSVData = data => {
    // Convert data to CSV format
    // Example: Convert array of objects to comma-separated values
    return data.map(item => Object.values(item).join(',')).join('\n');
  };
};

function ProductView() {
  const viewType = 'product_view';

  const { projectKey } = useParams();
  const [selectedBrand, setSelectedBrand] = useState(null);

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [inSaleStatus, setInSaleStatus] = useState(inSaleOptions[0]);

  const [selectedCountries, setSelectedCountries] = useState([]);
  const [countryButtonsStates, setCountryButtonsStates] = useState([])

  const [selectedRetailers, setSelectedRetailers] = useState([]);
  const [retailerButtonsStates, setRetailerButtonsStates] = useState([]);

  const [lineData, setLineData] = useState(null);
  const [gridData, setGridData] = useState(null);

  const [columnsUniqueValues, setColumnsUniqueValues] = useState(null);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);

  const [kpiData, setKpiData] = useState(null);

  const handleRetailerButtonClick = (index) => {
    // Toggle the state of the button at the specified index
    const updatedButtonsStates = [...retailerButtonsStates];
    updatedButtonsStates[index] = !updatedButtonsStates[index];
    setRetailerButtonsStates(updatedButtonsStates);

    const newSelectedRetailers = columnsUniqueValues?.Retailer.filter((_, index) => updatedButtonsStates[index]);
    setSelectedRetailers(newSelectedRetailers);
  };

  const handleCountryButtonClick = (index) => {
    // Toggle the state of the button at the specified index
    const updatedButtonsStates = [...countryButtonsStates];
    updatedButtonsStates[index] = !updatedButtonsStates[index];
    setCountryButtonsStates(updatedButtonsStates);

    const newSelectedCountries = columnsUniqueValues?.Country.filter((_, index) => updatedButtonsStates[index]);
    setSelectedCountries(newSelectedCountries);
  };

  const handleBrandChange = (event) => {
    setSelectedBrand(event.target.value);
  }

  const handleProductsChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedProducts(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  }

  const handleStartDateChange = (dateValue) => {
    setStartDate(dateValue);
  }

  const handleEndDateChange = (dateValue) => {
    setEndDate(dateValue);
  }

  // Modify columns to include renderCell for URLs
  const columnsWithClickableUrls = gridData?.columns.map((col) => {

    // TODO: Implement a better filtering than just "url" in column name (value based?)
    if (col.field.toLowerCase().includes('url')) {
      return {
        ...col,
        renderCell: (params) => (
          <a href={params.value} target="_blank" rel="noopener noreferrer">
            {params.value}
          </a>
        ),
      };
    }
    return col;
  });

  useEffect(() => {
    const fetchMinAndMaxDate = async () => {
      // TODO: Duplicated function (repeated in different views) --> deduplicate
      const result = await getMinAndMaxDate(projectKey, viewType);

      if (result) {
        const { min_date: minDateString, max_date: maxDateString } = await getMinAndMaxDate(projectKey, viewType);
        const newMinDate = dayjs(minDateString);
        const newMaxDate = dayjs(maxDateString);
        setMinDate(newMinDate);
        setMaxDate(newMaxDate);

        setStartDate(newMinDate);
        setEndDate(newMaxDate);
      } else {
        console.warn('Failed to fetch min and max dates, leaving default values.');
      }

    };

    const fetchColumnsUniqueValues = async () => {
      const data = await getColumnsUniqueValues(projectKey, viewType);

      if (data) {
        setSelectedCurrency(data.Currency[0]);
        setColumnsUniqueValues(data);
        setRetailerButtonsStates(new Array(data.Retailer.length).fill(true));
        setCountryButtonsStates(new Array(data.Country.length).fill(true));
      } else {
        console.warn('Failed to fetch columns unique values, leaving default values.');
      }
    };

    fetchMinAndMaxDate();
    fetchColumnsUniqueValues();
  }, []); // Empty dependency array to run only once on mount

  useEffect(() => {
    const fetchLineAndGridData = async () => {
      const startDateString = startDate ? startDate.format('YYYY-MM-DD') : null;
      const endDateString = endDate ? endDate.format('YYYY-MM-DD') : null;

      const data = await filterProjectProductViewData(
        projectKey,
        viewType,
        selectedCountries,
        selectedRetailers,
        selectedBrand,
        selectedProducts,
        selectedCurrency,
        inSaleStatus,
        startDateString,
        endDateString
      );

      if (data) {
        setLineData(data.graph_data);
        setGridData(data.grid_data);
        setKpiData(data.kpi_data);
        setColumnsUniqueValues({ ...columnsUniqueValues, ...data.columns_unique_values });
      } else {
        console.error('Fetching data failed. No data are being assigned.');
      }
    };

    fetchLineAndGridData();
  }, [
    projectKey,
    selectedCountries,
    selectedRetailers,
    selectedBrand,
    selectedProducts,
    selectedCurrency,
    inSaleStatus,
    startDate,
    endDate
  ]);

  return (
    <div style={{
      width: '70%',
      marginInline: 'auto'
    }}>
      <div style={{
        display: 'flex',
        gap: '1rem',
        margin: '1rem'
      }}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          minWidth: '20%',
          gap: '1rem',
        }}>
          <FormControl fullWidth size="small">
            <InputLabel id="brand-simple-select-label">
              Brand
            </InputLabel>
            <Select
              labelId="brand-simple-select-label"
              id="brand-simple-select"
              value={selectedBrand}
              label="Brand"
              onChange={handleBrandChange}
              MenuProps={{ PaperProps: { style: { maxHeight: '400px' } } }}
            >
              <MenuItem value="">-- choose --</MenuItem>
              {columnsUniqueValues?.Brand && columnsUniqueValues?.Brand.map((brand, index) => (
                <MenuItem key={index} value={brand}>{brand}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <Tooltip title={!selectedBrand ? 'Select Brand first' : ''} arrow>
            <FormControl fullWidth size="small">
              <InputLabel id="products-multiple-select-label">
                Product(s)
              </InputLabel>
              <Select
                labelId="products-multiple-select-label"
                id="products-multiple-select"
                value={selectedProducts}
                label="Product(s)"
                multiple
                onChange={handleProductsChange}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: '400px',
                      width: '300px',
                    },
                  },
                  MenuListProps: {
                    style: {
                      overflowX: 'scroll',
                    },
                  },
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                }}
                disabled={!selectedBrand}
              >
                <MenuItem value="">-- choose --</MenuItem>
                {columnsUniqueValues?.Title && columnsUniqueValues?.Title.map((product, index) => (
                  <MenuItem key={index} value={product}>{product}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Tooltip>
        </div>

        <div style={{
          display: 'flex',
          flexDirection: 'column',
          border: '1px solid #ccc',
          minWidth: '25%',
          height: '6rem',
          fontFamily: 'Arial, sans-serif',
          backgroundColor: '#f0f0f0',
          justifyContent: 'center'
        }}>
          <p style={{ marginLeft: '0.4rem', lineHeight: '0.2rem' }}><strong>EAN:</strong> { }</p>
          <p style={{ marginLeft: '0.4rem', lineHeight: '0.2rem' }}><strong>Brand:</strong> {selectedBrand}</p>
          <p style={{ marginLeft: '0.4rem', lineHeight: '0.2rem' }}><strong>Manufacturer:</strong> {selectedBrand}</p>
        </div>


        <div style={{
          display: 'flex',
          flexDirection: 'column',
          minWidth: '25%',
          gap: '1rem',
        }}>
          <FormControl fullWidth size='small'>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker slotProps={{ textField: { size: 'small' } }}
                label="Select start date"
                value={startDate}
                onChange={handleStartDateChange}
                disableFuture
                minDate={minDate}
                maxDate={maxDate}
              />
            </LocalizationProvider>
          </FormControl>

          <FormControl fullWidth size="small">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker slotProps={{ textField: { size: 'small' } }}
                label="Select end date"
                value={endDate}
                onChange={handleEndDateChange}
                disableFuture
                minDate={minDate}
                maxDate={maxDate}
              />
            </LocalizationProvider>
          </FormControl>
        </div>

        <div style={{
          display: 'flex',
          minWidth: '20%',
          gap: '1rem',
        }}>
          <FormControl fullWidth size='small' sx={{ minWidth: 120 }}>
            <InputLabel id="currency-simple-select-label">Currency</InputLabel>
            <Select
              labelId="currency-simple-select-label"
              id="currency-simple-select"
              value={selectedCurrency}
              label="Currency"
              onChange={(e) => setSelectedCurrency(e.target.value)}
            >
              {columnsUniqueValues?.Currency.map((currency, index) => (
                <MenuItem key={index} value={currency}>{currency}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth size='small' sx={{ minWidth: 120 }}>
            <InputLabel id="in-sale-select-label">In sale</InputLabel>
            <Select
              labelId="in-sale-select-label"
              id="in-sale-select"
              value={inSaleStatus}
              label="In sale"
              onChange={(e) => setInSaleStatus(e.target.value)}
            >
              {inSaleOptions.map((inSaleOption, index) => (
                <MenuItem key={index} value={inSaleOption}>{inSaleOption}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>

      <ThemeProvider theme={theme}>
        <div style={{
          margin: '1rem',
          overflowX: 'auto',
          whiteSpace: 'nowrap'
        }}>
          <Button
            variant="outlined"
            onClick={() => {
              if (countryButtonsStates.every(Boolean)) {
                setCountryButtonsStates(new Array(columnsUniqueValues?.Country.length).fill(false));
                setSelectedCountries([]);
              } else {
                setCountryButtonsStates(new Array(columnsUniqueValues?.Country.length).fill(true));
                setSelectedCountries(columnsUniqueValues?.Country);
              }
            }}
            style={{
              display: 'inline-block',
              marginRight: '1rem'
            }}
          >
            Select all
          </Button>
          {columnsUniqueValues?.Country.map((country, index) => (
            <Button
              variant={countryButtonsStates[index] ? "contained" : "outlined"} // Change color based on state
              onClick={() => handleCountryButtonClick(index)}
              style={{
                display: 'inline-block',
                marginRight: '1rem'
              }}
            >
              {country}
            </Button>

          ))}
        </div>

        <div style={{
          margin: '1rem',
          overflowX: 'auto',
          whiteSpace: 'nowrap'
        }}>
          <Button
            variant="outlined"
            onClick={() => {
              if (retailerButtonsStates.every(Boolean)) {
                setRetailerButtonsStates(new Array(columnsUniqueValues?.Retailer.length).fill(false));
                setSelectedRetailers([]);
              } else {
                setRetailerButtonsStates(new Array(columnsUniqueValues?.Retailer.length).fill(true));
                setSelectedRetailers(columnsUniqueValues?.Retailer);
              }
            }}
            style={{
              display: 'inline-block',
              marginRight: '1rem'
            }}
          >
            Select all
          </Button>
          {columnsUniqueValues?.Retailer.map((retailer, index) => (
            <Button
              key={index}
              variant={retailerButtonsStates[index] ? "contained" : "outlined"} // Change color based on state
              onClick={() => handleRetailerButtonClick(index)}
              style={{
                display: 'inline-block',
                marginRight: '1rem'
              }}
            >
              {retailer}
            </Button>
          ))}
        </div>
      </ThemeProvider>

      <div style={{
        display: 'flex',
        gap: '1rem',
        margin: '1rem'
      }}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          width: '80%'
        }}>
          {LinePlot(lineData, 'Average Price', selectedCurrency, 'Retailers')}

          <div>
            {gridData ? (
              <DataGrid
                rowHeight={35}
                rows={gridData.rows}
                columns={columnsWithClickableUrls.map(column => ({
                  ...column,
                  resizable: true, // Enable resizable for each column
                  headerAlign: 'center',
                }))}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 100,
                    },
                  },
                }}
                pageSizeOptions={[25, 50, 100]}
                disableSelectionOnClick
                density='compact'
                sx={{
                  height: '350px', // Adjust the height to show ~10 rows (10 * 35 rowHeight)
                  overflowX: 'scroll'
                }}
              />
            ) : (
              <div>Loading...</div>
            )}
          </div>
        </div>

        <div style={{
          width: '20%'
        }}>
          {KpiElements(kpiData)}
        </div>
      </div>
    </div>
  );
}

export default ProductView;