import { Line } from "react-chartjs-2";
import {
  Chart,
  registerables
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('.chartjs-tooltip');
  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.className = 'chartjs-tooltip';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.background = 'rgba(0, 0, 0, 0.85)';
    tooltipEl.style.color = 'white';
    tooltipEl.style.borderRadius = '6px';
    tooltipEl.style.pointerEvents = 'auto';  // Allows mouse events on the tooltip
    tooltipEl.style.transition = 'all 0.2s ease';
    tooltipEl.style.overflowY = 'auto';
    tooltipEl.style.maxHeight = '40%';
    tooltipEl.style.maxWidth = '300px';
    tooltipEl.style.zIndex = '1001'; // Ensure it's above all other elements

    tooltipEl.onmouseenter = () => chart.tooltipActive = true;
    tooltipEl.onmouseleave = () => {
      chart.tooltipActive = false;
      tooltipEl.style.opacity = 0;
    };

    const table = document.createElement('table');
    table.style.margin = '0px';
    table.style.width = '100%'; // Use the full width of the tooltip
    table.style.tableLayout = 'auto';  // Forces the table to respect column widths
    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }
  return tooltipEl;
}

const externalTooltipHandler = (context) => {
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  if (tooltip.opacity === 0 || chart.tooltipActive) {
    if (!chart.tooltipActive) {
      tooltipEl.style.opacity = 0;
    }
    return;
  }

  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map(b => b.lines);
    const labelColors = tooltip.labelColors;

    const tableRoot = tooltipEl.querySelector('table');
    while (tableRoot.firstChild) {
      tableRoot.removeChild(tableRoot.firstChild);
    }

    const tableHead = document.createElement('thead');
    titleLines.forEach(title => {
      const tr = document.createElement('tr');
      const th = document.createElement('th');
      th.style.font = 'bold 10px Arial';
      th.style.color = 'white';
      th.style.background = 'rgba(0, 0, 0, 0.85)';
      th.textContent = title;
      th.colSpan = 3;
      tr.appendChild(th);
      tableHead.appendChild(tr);
    });

    const tableBody = document.createElement('tbody');
    bodyLines.forEach((lines, i) => {
      lines.forEach(line => {
        const parts = line.split(':');
        const name = parts[0].trim() + ':';
        const value = parts[1].trim();

        const tr = document.createElement('tr');
        const tdColor = document.createElement('td');
        tdColor.style.background = labelColors[i].backgroundColor;
        tdColor.style.maxWidth = '10px';
        tdColor.style.height = '10px';

        const tdLeft = document.createElement('td');
        tdLeft.style.textAlign = 'left';
        tdLeft.style.font = '10px Arial';
        tdLeft.textContent = name;

        const tdRight = document.createElement('td');
        tdRight.style.textAlign = 'right';
        tdRight.style.font = '10px Arial';
        tdRight.textContent = value;

        // Ensure no hover color change
        tdColor.style.pointerEvents = 'none';
        tdLeft.style.pointerEvents = 'none';
        tdRight.style.pointerEvents = 'none';

        tr.appendChild(tdColor);
        tr.appendChild(tdLeft);
        tr.appendChild(tdRight);
        tableBody.appendChild(tr);
      });
    });

    tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);
  }

  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = chart.canvas.offsetLeft + tooltip.caretX + 'px';
  tooltipEl.style.top = chart.canvas.offsetTop + tooltip.caretY + 'px';
};

const verticalLinePlugin = {
  id: 'verticalLineOnHover',
  afterTooltipDraw: function (chart, args) {
    const { ctx, chartArea, tooltip } = chart;
    if (tooltip.getActiveElements().length > 0) {
      const activeElement = tooltip.getActiveElements()[0];
      const x = activeElement.element.x;

      ctx.save();
      ctx.beginPath();
      ctx.moveTo(x, chartArea.bottom);
      ctx.lineTo(x, chartArea.top);
      ctx.lineWidth = 1; // Line thickness
      ctx.strokeStyle = 'black'; // Line color
      ctx.stroke();
      ctx.restore();
    }
  }
};

const tooltipScrollbarPlugin = {
  id: 'tooltipScrollbar',
  beforeTooltipDraw(chart) {
    const tooltip = chart.tooltip;
    if (!tooltip.isVisible) {
      return;
    }

    // Use the chart's height as the maximum height for the tooltip
    const chartHeight = chart.chartArea.height;

    // Modify tooltip height based on the chart's height
    tooltip.update({
      height: function (context) {
        const tooltipHeight = context.height;
        if (tooltipHeight > chartHeight) {
          return chartHeight;
        }
        return tooltipHeight;
      }
    });
  },
  afterTooltipDraw(chart) {
    const tooltip = chart.tooltip;
    if (!tooltip.isVisible) {
      return;
    }

    const ctx = chart.ctx;
    const tooltipHeight = tooltip.height;
    const chartHeight = chart.chartArea.height;

    if (tooltipHeight > chartHeight) {
      // Calculate scroll bar height proportional to the content
      const scrollHeight = chartHeight / tooltipHeight;
      const scrollPosY = (tooltip.y - tooltip.height / 2) + scrollHeight * (tooltip.yAlign === 'top' ? 0 : chartHeight);

      // Style and position the scrollbar
      ctx.save();
      ctx.fillStyle = 'rgba(150, 150, 150, 0.8)';
      ctx.fillRect(tooltip.caretX + tooltip.width / 2 + 2, scrollPosY, 5, scrollHeight * chartHeight);
      ctx.restore();
    }
  }
};

Chart.register(...registerables, verticalLinePlugin, tooltipScrollbarPlugin, ChartDataLabels);

function LinePlot(lineData, yAxisTitle, yAxisUnit, legendTitle) {
  const lineChartOptions = {
    // aspectRatio: 4,
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    stacked: false,
    scales: {
      x: {
        ticks: {
          autoSkip: true,
          maxTicksLimit: 10
        }
      },
      y: {
        title: {
          display: true,
          text: yAxisUnit ? `${yAxisTitle} (${yAxisUnit})` : yAxisTitle,
          font: {
            size: 18,
            weight: 'bold'
          }
        }
      }
    },
    elements: {
      point: {
        radius: 0
      }
    },
    layout: {
      padding: {
        right: 128,
        top: 10,
        left: 10,
        bottom: 10
      },
    },
    plugins: {
      legend: {
        position: 'top',
        title: {
          display: true,
          text: legendTitle,
          font: {
            size: 14,
            weight: 'bold'
          }
        }
      },
      tooltip: {
        enabled: false,
        position: 'nearest',
        external: externalTooltipHandler,
        itemSort: function (a, b) {
          return b.raw - a.raw; // Sorts tooltip items in descending order
        }
      },
      datalabels: {
        align: 'right',
        color: (ctx) => ctx.dataset.borderColor,
        display: (ctx) => ctx.dataIndex === ctx.dataset.data.length - 1,
        formatter: (v, ctx) => ctx.dataset.label,
        offset: 8,
        font: {
          weight: 600,
          size: 10
        },
      }
    }
  };

  return (
    <div style={{
      border: '1px solid #ccc',
      borderRadius: '4px'
    }}>
      {lineData ? (
        <Line
          key={'line_chart'}
          options={lineChartOptions}
          data={lineData}
        />
      ) : (
        <div>Loading...</div>
      )}
    </div>
  )
};

export default LinePlot;
