import { Bar } from "react-chartjs-2";

function BarPlot(
  data,
  leftAxisTitle,
  leftAxisUnit,
  rightAxisTitle,
  rightAxisUnit,
  legendTitle
) {
  const config = {
    responsive: true,
    scales: {
      x: {
        stacked: true,
        ticks: {
          autoSkip: true,
          maxTicksLimit: 10
        },
        gridLines: {
          display: false,
          drawOnChartArea: false
        }
      },
      y: {
        beginAtZero: true,
        position: 'left',
        stacked: true,
        id: 'y',
        title: {
          display: true,
          text: leftAxisUnit ? `${leftAxisTitle} (${leftAxisUnit})` : leftAxisTitle,
          font: {
            size: 18,
            weight: 'bold'
          }
        },
        gridLines: {
          display: false,
          drawOnChartArea: false
        }
      },
      y1: {
        beginAtZero: true,
        minBarLenght: 5,
        position: 'right',
        stacked: true,
        id: 'y1',
        title: {
          display: true,
          text: rightAxisUnit ? `${rightAxisTitle} (${rightAxisUnit})` : rightAxisTitle,
          font: {
            size: 18,
            weight: 'bold'
          }
        }
      }
    },
    layout: {
      padding: {
        right: 10,
        top: 10,
        left: 10,
        bottom: 10
      },
    },
    plugins: {
      legend: {
        position: 'top',
        title: {
          display: true,
          text: legendTitle,
          font: {
            size: 14,
            weight: 'bold'
          }
        }
      },
      datalabels: {
        display: false
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      }
    }
  };

  return (
    <div style={{
      border: '1px solid #ccc',
      borderRadius: '4px'
    }}>
      {data ? (
        <Bar
          key={'bar_chart'}
          options={config}
          data={data}
        />
      ) : (
        <div>Loading...</div>
      )}
    </div>
  )
};

export default BarPlot;
