import React from 'react';
import { Card, CardContent, Typography, Stack } from '@mui/material';
import { ArrowDown as ArrowDownIcon } from '@phosphor-icons/react/dist/ssr/ArrowDown';
import { ArrowUp as ArrowUpIcon } from '@phosphor-icons/react/dist/ssr/ArrowUp';

const getKpiArrowIcon = (trend) => {
  return trend === 'up' ? (
    <ArrowUpIcon style={{ color: 'green' }} />
  ) : (
    <ArrowDownIcon style={{ color: 'red' }} />
  );
};

function KpiElements(kpiData) {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      alignItems: 'center'
    }}>
      {kpiData && Object.keys(kpiData).map((key) => (
        <Card key={key} sx={{
          width: '80%',
          height: `${100 / Object.keys(kpiData).length}%`,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <CardContent sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography color="text.secondary" variant="overline" sx={{ fontSize: '0.9rem', fontWeight: 'bold' }}>
              {kpiData[key].title}
            </Typography>
            <Typography sx={{ fontSize: '2rem' }}>{kpiData[key].value}</Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              {getKpiArrowIcon(kpiData[key].trend)}
              <Typography color={kpiData[key].trend === 'up' ? 'green' : 'red'} variant="body2">
                {kpiData[key].diff} ({kpiData[key].relative_diff} %)
              </Typography>
            </Stack>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default KpiElements;
