import React, { useState } from 'react';
import axios from 'axios';

import { useNavigate } from 'react-router-dom';

import { Button, TextField, ThemeProvider, createTheme, Typography  } from '@mui/material';
import './SimpleLogin.css'; // Import CSS file for styling
//const API_URL = 'http://localhost:8000';  // Your FastAPI server URL
const API_URL = 'https://app.forloop.ai:8001';

const theme = createTheme({
  palette: {
    primary: {
      main: '#000000', // Black color
    },
  },
});



const SimpleLogin = ({ isLoggedIn, setIsLoggedIn, setProjectKey }) => {
  
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  //const [isLoggedIn, setIsLoggedIn] = useState(false); // State to track login status

  

  const handleLogin = async () => {
    try {
      const response = await axios.post(
        `${API_URL}/login`,
        {
          username: username,
          password: password
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.status === 200) {
        console.log('Login successful:', response.data);
        setError('');
        setIsLoggedIn(true); // Set isLoggedIn to true upon successful login
        console.log(response.data)
        setProjectKey(response.data.project_key)
        // Redirect or perform further actions upon successful login
        navigate('/'+username+'/retailer');
      } else {
        throw new Error(response.data.detail);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    handleLogin(); // Call handleLogin when the form is submitted
  };

  return (
    <div>


<ThemeProvider theme={theme}>
<div>
      {isLoggedIn ? (
        <div>
          <Typography variant="h5" gutterBottom>Welcome, {username}!</Typography>
          <Typography>You are successfully logged in.</Typography>
        </div>
      ) : (
        <div className="login-container">
          <h2>Login</h2>
          <div className="input-container">
            <TextField
              label="Username"
              variant="outlined"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="input-container">
            <TextField
              label="Password"
              variant="outlined"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <Button variant="contained" color="primary" onClick={handleLogin}>Login</Button>
          {error && <p className="error-message">{error}</p>}
        </div>
      )}
    </div>
   
   </ThemeProvider>
    </div>
    
   
          
    
  );
};

export default SimpleLogin;
