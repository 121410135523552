import axios from 'axios';

//const API_URL = 'http://localhost:8000';  // Your FastAPI server URL
const API_URL = 'https://app.forloop.ai:8001';

const abortControllers = {};

export const fetchHistogramData = async () => {
    try {
        const response = await axios.get(`${API_URL}/histogram-data`);
        return response.data;
    } catch (error) {
        console.error('Error fetching histogram data:', error);
        return null;
    }
};


export const fetchPositionData = async (retailer, category, year, month, day) => {
    try {
        const response = await axios.get(`${API_URL}/position_data?retailer=${retailer}&category=${category}&year=${year}&month=${month}&day=${day}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching histogram data:', error);
        return null;
    }
};


export const fetchPlacementData = async (retailer, category, year, month, day) => {
    try {
        const response = await axios.get(`${API_URL}/placement_data`, {
            params: {
                retailer: retailer,
                category: category,
                year: year,
                month: month,
                day: day
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching placement data:', error);
        return null;
    }
};

export const getColumnsUniqueValues = async (projectKey, viewType) => {
    const endpoint = `${API_URL}/${projectKey}/${viewType}/columns_unique_values`;

    // Check if there is a previous request for this endpoint and abort it
    if (abortControllers[endpoint]) {
        abortControllers[endpoint].abort('Operation canceled due to new request.');
    }

    // Create a new AbortController instance
    const controller = new AbortController();
    abortControllers[endpoint] = controller;

    try {
        const { data } = await axios.get(endpoint, {
            signal: controller.signal
        });
        return data;
    } catch (error) {
        if (axios.isCancel(error)) {
            console.log(`Request '${endpoint}' canceled:`, error.message);
        } else {
            console.error('Error fetching columns unique values:', error);
        }
        return null;
    }
};

export const getMinAndMaxDate = async (projectKey, viewType) => {
    const endpoint = `${API_URL}/${projectKey}/${viewType}/min_and_max_date`;

    // Check if there is a previous request for this endpoint and abort it
    if (abortControllers[endpoint]) {
        abortControllers[endpoint].abort('Operation canceled due to new request.');
    }

    // Create a new AbortController instance
    const controller = new AbortController();
    abortControllers[endpoint] = controller;

    try {
        const { data } = await axios.get(endpoint, {
            signal: controller.signal
        });
        return data;
    } catch (error) {
        if (axios.isCancel(error)) {
            console.log(`Request '${endpoint}' canceled:`, error.message);
        } else {
            console.error('Error fetching min and max dates:', error);
        }
        return null;
    }
};

export const filterProjectProductViewData = async (
    projectKey,
    viewType,
    countries,
    retailers,
    brand,
    products,
    currency,
    inSaleStatus,
    startDateString,
    endDateString
) => {
    const endpoint = `${API_URL}/${projectKey}/${viewType}/page_data`;

    // Check if there is a previous request for this endpoint and abort it
    if (abortControllers[endpoint]) {
        abortControllers[endpoint].abort('Operation canceled due to new request.');
    }

    // Create a new AbortController instance
    const controller = new AbortController();
    abortControllers[endpoint] = controller;

    try {
        const { data } = await axios.post(endpoint, {
            countries: countries,
            retailers: retailers,
            brands: brand ? [brand] : null,
            products: products,
            currency: currency,
            in_sale_status: inSaleStatus,
            start_date: startDateString,
            end_date: endDateString,
        }, {
            signal: controller.signal,
            headers: {
                'content-type': 'application/json'
            }
        });
        return data;
    } catch (error) {
        if (axios.isCancel(error)) {
            console.log(`Request '${endpoint}' canceled:`, error.message);
        } else {
            console.error('Error fetching product view data:', error);
        }
        return null;
    }
};

export const filterProjectRetailerViewData = async (
    projectKey,
    viewType,
    retailers,
    brands,
    products,
    currency,
    startDateString,
    endDateString
) => {
    const endpoint = `${API_URL}/${projectKey}/${viewType}/page_data`;

    // Check if there is a previous request for this endpoint and abort it
    if (abortControllers[endpoint]) {
        abortControllers[endpoint].abort('Operation canceled due to new request.');
    }

    // Create a new AbortController instance
    const controller = new AbortController();
    abortControllers[endpoint] = controller;

    try {
        const { data } = await axios.post(endpoint, {
            retailers: retailers,
            brands: brands,
            products: products,
            currency: currency,
            start_date: startDateString,
            end_date: endDateString
        }, {
            signal: controller.signal,
            headers: {
                'content-type': 'application/json'
            }
        });
        return data;
    } catch (error) {
        console.error('Error fetching retailer view data:', error);
        return null;
    }
};

export const filterProjectOutOfStockData = async (
    projectKey,
    viewType,
    retailers,
    brands,
    statuses,
    oosWhileInSale,
    startDateString,
    endDateString
) => {
    const endpoint = `${API_URL}/${projectKey}/${viewType}/page_data`;

    // Check if there is a previous request for this endpoint and abort it
    if (abortControllers[endpoint]) {
        abortControllers[endpoint].abort('Operation canceled due to new request.');
    }

    // Create a new AbortController instance
    const controller = new AbortController();
    abortControllers[endpoint] = controller;

    try {
        const { data } = await axios.post(endpoint, {
            retailers: retailers,
            brands: brands,
            statuses: statuses,
            oos_while_in_sale: oosWhileInSale,
            start_date: startDateString,
            end_date: endDateString
        }, {
            signal: controller.signal,
            headers: {
                'content-type': 'application/json'
            }
        });
        return data;
    } catch (error) {
        if (axios.isCancel(error)) {
            console.log(`Request '${endpoint}' aborted:`, error.message);
        } else {
            console.error('Error fetching distribution view (brand scope) data:', error);
        }
        return null;
    }
};

