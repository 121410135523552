import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { FormControl, InputLabel, MenuItem, Select, Tooltip } from '@mui/material';
import { getMinAndMaxDate, getColumnsUniqueValues, filterProjectRetailerViewData } from './api';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useParams } from "react-router-dom";

import 'chartjs-plugin-crosshair';
import dayjs from 'dayjs';

import LinePlot from './LinePlot';
import KpiElements from './KpiElement';

function RetailerView() {
  const viewType = 'retailer_view';

  const { projectKey } = useParams();

  const [selectedRetailer, setSelectedRetailer] = useState(null);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const [lineData, setLineData] = useState(null);
  const [gridData, setGridData] = useState(null);

  const [columnsUniqueValues, setColumnsUniqueValues] = useState(null);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);

  const [kpiData, setKpiData] = useState(null);

  const handleRetailerChange = (event) => {
    setSelectedRetailer(event.target.value);
    setSelectedProducts([]);
  }

  const handleBrandsChange = (event) => {
    const {
      target: { value },
    } = event;
    let newSelectedBrands = typeof value === 'string' ? value.split(',') : value;
    newSelectedBrands = newSelectedBrands.includes("") ? [] : newSelectedBrands;
    setSelectedBrands(newSelectedBrands);
  }

  const handleProductsChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedProducts(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  }

  const handleStartDateChange = (dateValue) => {
    setStartDate(dateValue);
  }

  const handleEndDateChange = (dateValue) => {
    setEndDate(dateValue);
  }

  // Modify columns to include renderCell for URLs
  const columnsWithClickableUrls = gridData?.columns.map((col) => {

    // TODO: Implement a better filtering than just "url" in column name (value based?)
    if (col.field.toLowerCase().includes('url')) {
      return {
        ...col,
        renderCell: (params) => (
          <a href={params.value} target="_blank" rel="noopener noreferrer">
            {params.value}
          </a>
        ),
      };
    }
    return col;
  });

  useEffect(() => {
    const fetchMinAndMaxDate = async () => {
      // TODO: Duplicated function (repeated in different views) --> deduplicate
      const result = await getMinAndMaxDate(projectKey, viewType);

      if (result) {
        const { min_date: minDateString, max_date: maxDateString } = await getMinAndMaxDate(projectKey, viewType);
        const newMinDate = dayjs(minDateString);
        const newMaxDate = dayjs(maxDateString);
        setMinDate(newMinDate);
        setMaxDate(newMaxDate);

        setStartDate(newMinDate);
        setEndDate(newMaxDate);
      } else {
        console.warn('Failed to fetch min and max dates, leaving default values.');
      }
    };

    const fetchColumnsUniqueValues = async () => {
      const data = await getColumnsUniqueValues(projectKey, viewType);
      if (data) {
        setSelectedRetailer(data.Retailer[0]);
        setSelectedCurrency(data.Currency[0]);
        setColumnsUniqueValues(data);
      } else {
        console.warn('Failed to fetch columns unique values, leaving default values.');
      }
    };

    fetchMinAndMaxDate();
    fetchColumnsUniqueValues();
  }, []); // Empty dependency array to run only once on mount

  useEffect(() => {
    const fetchLineAndGridData = async () => {
      const startDateString = startDate ? startDate.format('YYYY-MM-DD') : null;
      const endDateString = endDate ? endDate.format('YYYY-MM-DD') : null;

      const data = await filterProjectRetailerViewData(
        projectKey,
        viewType,
        selectedRetailer ? [selectedRetailer] : [],
        selectedBrands,
        selectedProducts,
        selectedCurrency,
        startDateString,
        endDateString
      );

      if (data) {
        setLineData(data.graph_data);
        setGridData(data.grid_data);
        setKpiData(data.kpi_data);
        setColumnsUniqueValues({ ...columnsUniqueValues, ...data.columns_unique_values });
      } else {
        console.error('Fetching data failed. No data are being assigned.');
      }
    };

    fetchLineAndGridData();
  }, [projectKey, selectedRetailer, selectedBrands, selectedCurrency, selectedProducts, startDate, endDate]);

  return (
    <div style={{
      width: '70%',
      marginInline: 'auto'
    }}>
      <div style={{
        display: 'flex',
        gap: '1rem',
        margin: '1rem'
      }}>
        {/* Retailer dropdown */}
        <FormControl fullWidth size="small">
          <InputLabel id="retailer-simple-select-label">Retailer</InputLabel>
          <Select
            labelId="retailer-simple-select-label"
            id="retailer-simple-select"
            value={selectedRetailer}
            label="Retailer"
            onChange={handleRetailerChange}
            MenuProps={{ PaperProps: { style: { maxHeight: '400px' } } }}
          >
            {columnsUniqueValues?.Retailer && columnsUniqueValues?.Retailer.map((retailer, index) => (
              <MenuItem key={index} value={retailer}>{retailer}</MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Brands dropdown */}
        <Tooltip title={!selectedRetailer ? 'Select Retailer first' : ''}>
          <FormControl fullWidth size="small">
            <InputLabel id="brands-multiple-select-label">Brands</InputLabel>
            <Select
              labelId="brands-multiple-select-label"
              id="brands-multiple-select"
              value={selectedBrands}
              label="Brand"
              multiple
              onChange={handleBrandsChange}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={{ PaperProps: { style: { maxHeight: '400px' } } }}
              disabled={!selectedRetailer}
            >
              <MenuItem value="">-- choose --</MenuItem>
              {columnsUniqueValues?.Brand && columnsUniqueValues?.Brand.map((brand, index) => (
                <MenuItem key={index} value={brand}>{brand}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Tooltip>

        {/* Products dropdown */}
        <Tooltip title={!(selectedBrands && selectedBrands.length) ? 'Select Brand(s) first' : ''} arrow>
          <FormControl fullWidth size="small">
            <InputLabel id="products-multiple-select-label">Product(s)</InputLabel>
            <Select
              labelId="products-multiple-select-label"
              id="products-multiple-select"
              value={selectedProducts}
              label="Product(s)"
              multiple
              onChange={handleProductsChange}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={{ PaperProps: { style: { maxHeight: '400px' } } }}
              disabled={!(selectedBrands && selectedBrands.length)}
            >
              <MenuItem value="">-- choose --</MenuItem>
              {columnsUniqueValues?.Title && columnsUniqueValues?.Title.map((product, index) => (
                <MenuItem key={index} value={product}>{product}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Tooltip>

        {/* Date Pickers */}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <FormControl fullWidth size="small">
            <DatePicker slotProps={{ textField: { size: 'small' } }}
              label="Start Date"
              value={startDate}
              onChange={handleStartDateChange}
              disableFuture
              minDate={minDate}
              maxDate={maxDate}
            />
          </FormControl>
          <FormControl fullWidth size="small">
            <DatePicker slotProps={{ textField: { size: 'small' } }}
              label="End Date"
              value={endDate}
              onChange={handleEndDateChange}
              disableFuture
              minDate={minDate}
              maxDate={maxDate}
            />
          </FormControl>
        </LocalizationProvider>

        <FormControl fullWidth size='small'>
          <InputLabel id="currency-simple-select-label">Currency</InputLabel>
          <Select
            labelId="currency-simple-select-label"
            id="currency-simple-select"
            value={selectedCurrency}
            label="Currency"
            onChange={(e) => setSelectedCurrency(e.target.value)}
          >
            {columnsUniqueValues?.Currency.map((currency, index) => (
              <MenuItem key={index} value={currency}>{currency}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <div style={{
        display: 'flex',
        gap: '1rem',
        margin: '1rem'
      }}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          width: '80%'
        }}>
          {LinePlot(lineData, 'Average Price', null, 'Retailers')}

          <div>
            {gridData ? (
              <DataGrid
                rowHeight={35}
                rows={gridData.rows}
                columns={columnsWithClickableUrls.map(column => ({
                  ...column,
                  resizable: true, // Enable resizable for each column
                  headerAlign: 'center',
                }))}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 100,
                    },
                  },
                }}
                pageSizeOptions={[25, 50, 100]}
                disableSelectionOnClick
                density='compact'
                sx={{
                  height: '350px', // Adjust the height to show ~10 rows (10 * 35 rowHeight)
                  overflowX: 'scroll'
                }}
              />
            ) : (
              <div>Loading...</div>
            )}
          </div>
        </div>

        <div style={{
          width: '20%'
        }}>
          {KpiElements(kpiData)}
        </div>
      </div>
    </div>
  );
}

export default RetailerView;