import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { FormControl, InputLabel, MenuItem, Select, Button, Tooltip } from '@mui/material';
import { getMinAndMaxDate, getColumnsUniqueValues, filterProjectOutOfStockData } from './api';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useParams } from "react-router-dom";
import dayjs from 'dayjs';
import KpiElements from './KpiElement';
import BarPlot from './BarPlot';

function BrandDistributionView() {
  const viewType = 'distribution_view_brand';

  const leftAxisTitle = 'Products total';
  const leftAxisUnit = '#';
  const rightAxisTitle = 'Additions / Removals';
  const rightAxisUnit = '#';
  const legendTitle = 'Product';

  const { projectKey } = useParams();

  const [columnsUniqueValues, setColumnsUniqueValues] = useState(null);

  const [selectedBrand, setSelectedBrand] = useState('');
  const [selectedRetailers, setSelectedRetailers] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [oosWhileInSaleStatus, setOosWhileInSaleStatus] = useState(false);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);

  const [barData, setBarData] = useState(null);
  const [gridData, setGridData] = useState(null);
  const [kpiData, setKpiData] = useState(null);

  const handleRetailersChange = (event) => {
    const {
      target: { value },
    } = event;
    let newSelectedRetailers = typeof value === 'string' ? value.split(',') : value;

    if (newSelectedRetailers.includes('all')) {
      if (newSelectedRetailers.length >= columnsUniqueValues?.Retailer.length) {
        newSelectedRetailers = [];
      } else {
        newSelectedRetailers = columnsUniqueValues?.Retailer;
      }
    }

    setSelectedRetailers(newSelectedRetailers);
  }

  const handleStatusesChange = (event) => {
    const {
      target: { value },
    } = event;

    let newSelectedStatuses = typeof value === 'string' ? value.split(',') : value;

    if (newSelectedStatuses.includes('all')) {
      if (newSelectedStatuses.length >= columnsUniqueValues?.Status.length) {
        newSelectedStatuses = [];
      } else {
        newSelectedStatuses = columnsUniqueValues?.Status;
      }
    }

    setSelectedStatuses(newSelectedStatuses);
  }

  // Modify columns to include renderCell for URLs
  const columnsWithClickableUrls = gridData?.columns.map((col) => {

    // TODO: Implement a better filtering than just "url" in column name (value based?)
    if (col.field.toLowerCase().includes('url')) {
      return {
        ...col,
        renderCell: (params) => (
          <a href={params.value} target="_blank" rel="noopener noreferrer">
            {params.value}
          </a>
        ),
      };
    }
    return col;
  });

  useEffect(() => {
    const fetchMinAndMaxDate = async () => {
      // TODO: Duplicated function (repeated in different views) --> deduplicate
      const result = await getMinAndMaxDate(projectKey, viewType);

      if (result) {
        const { min_date: minDateString, max_date: maxDateString } = result;

        const newMinDate = dayjs(minDateString);
        const newMaxDate = dayjs(maxDateString);
        setMinDate(newMinDate);
        setMaxDate(newMaxDate);

        setStartDate(newMinDate);
        setEndDate(newMaxDate);
      } else {
        console.warn('Failed to fetch min and max dates, leaving default values.');
      }
    };

    const fetchColumnsUniqueValues = async () => {
      const data = await getColumnsUniqueValues(projectKey, viewType);
      setColumnsUniqueValues(data);
      setSelectedBrand(data?.Brand[0]);
    };

    fetchMinAndMaxDate();
    fetchColumnsUniqueValues();
  }, []); // Empty dependency array to run only once on mount

  useEffect(() => {
    const fetchLineAndGridData = async () => {
      const startDateString = startDate ? startDate.format('YYYY-MM-DD') : null;
      const endDateString = endDate ? endDate.format('YYYY-MM-DD') : null;

      const data = await filterProjectOutOfStockData(
        projectKey,
        viewType,
        selectedRetailers,
        selectedBrand ? [selectedBrand] : [],
        selectedStatuses,
        oosWhileInSaleStatus,
        startDateString,
        endDateString
      );

      if (data) {
        setBarData(data.graph_data);
        setGridData(data.grid_data);
        setKpiData(data.kpi_data);
        setColumnsUniqueValues({ ...columnsUniqueValues, ...data.columns_unique_values });
      } else {
        console.error('Fetching data failed. No data are being assigned.');
      }
    };

    fetchLineAndGridData();
  }, [projectKey, selectedBrand, selectedRetailers, selectedStatuses, oosWhileInSaleStatus, startDate, endDate]);

  return (
    <div style={{
      width: '70%',
      marginInline: 'auto'
    }}>
      <div style={{
        display: 'flex',
        gap: '1rem',
        margin: '1rem'
      }}>
        <FormControl fullWidth size="small">
          <InputLabel id="brand-simple-select-label">
            Brand
          </InputLabel>
          <Select
            labelId="brand-simple-select-label"
            id="brand-simple-select"
            value={selectedBrand}
            label="Brand"
            onChange={(e) => setSelectedBrand(e.target.value)}
            MenuProps={{ PaperProps: { style: { maxHeight: '400px' } } }}
          >
            {columnsUniqueValues?.Brand && columnsUniqueValues?.Brand.map((brand, index) => (
              <MenuItem key={index} value={brand}>{brand}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <Tooltip title={!selectedBrand ? 'Select Brand first' : ''} arrow>
          <FormControl fullWidth size="small">
            <InputLabel id="retailers-multiple-select-label">
              Retailer(s)
            </InputLabel>
            <Select
              labelId="retailers-multiple-select-label"
              id="retailers-multiple-select"
              value={selectedRetailers}
              label="Retailer(s)"
              multiple
              onChange={handleRetailersChange}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={{ PaperProps: { style: { maxHeight: '400px' } } }}
              disabled={!selectedBrand}
            >
              <MenuItem value="all">Select all</MenuItem>
              {columnsUniqueValues?.Retailer && columnsUniqueValues?.Retailer.map((product, index) => (
                <MenuItem key={'title_' + index} value={product}>{product}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Tooltip>

        <FormControl fullWidth size="small">
          <InputLabel id="status-multiple-select-label">
            Status
          </InputLabel>
          <Select
            labelId="status-multiple-select-label"
            id="status-multiple-select"
            value={selectedStatuses}
            label="Status"
            multiple
            onChange={handleStatusesChange}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={{ PaperProps: { style: { maxHeight: '400px' } } }}
          >
            <MenuItem value="all">Select all</MenuItem>
            {columnsUniqueValues?.Status && columnsUniqueValues?.Status.map((product, index) => (
              <MenuItem key={'title_' + index} value={product}>{product}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          key='oos_while_in_sale_button'
          variant={oosWhileInSaleStatus ? "contained" : "outlined"} // Change color based on state
          onClick={() => setOosWhileInSaleStatus(!oosWhileInSaleStatus)}
          sx={{
            whiteSpace: 'nowrap',   // Prevents wrapping
            textOverflow: 'ellipsis', // Adds ellipsis if text overflows
            overflow: 'hidden', // Ensures no overflow
            width: 'auto',      // Adjust width based on text
            minWidth: 'fit-content', // Minimum width to fit content
          }}
        >
          OOS while in sale
        </Button>

        <FormControl fullWidth size='small'>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker slotProps={{ textField: { size: 'small' } }}
              label="Select start date"
              value={startDate}
              onChange={(dateValue) => setStartDate(dateValue)}
              disableFuture
              minDate={minDate}
              maxDate={maxDate}
            />
          </LocalizationProvider>
        </FormControl>

        <FormControl fullWidth size="small">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker slotProps={{ textField: { size: 'small' } }}
              label="Select end date"
              value={endDate}
              onChange={(dateValue) => setEndDate(dateValue)}
              disableFuture
              minDate={minDate}
              maxDate={maxDate}
            />
          </LocalizationProvider>
        </FormControl>
      </div>

      <div style={{
        display: 'flex',
        gap: '1rem',
        margin: '1rem'
      }}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          width: '80%'
        }}>
          {BarPlot(barData, leftAxisTitle, leftAxisUnit, rightAxisTitle, rightAxisUnit, legendTitle)}

          <div>
            {gridData ? (
              <DataGrid
                rowHeight={35}
                rows={gridData.rows}
                columns={columnsWithClickableUrls.map(column => ({
                  ...column,
                  resizable: true, // Enable resizable for each column
                  headerAlign: 'center',
                }))}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 100,
                    },
                  },
                }}
                pageSizeOptions={[25, 50, 100]}
                disableSelectionOnClick
                density='compact'
                sx={{
                  height: '350px', // Adjust the height to show ~10 rows (10 * 35 rowHeight)
                  overflowX: 'scroll'
                }}
              />
            ) : (
              <div>Loading...</div>
            )}
          </div>
        </div>

        <div style={{
          width: '20%'
        }}>
          {KpiElements(kpiData)}
        </div>
      </div>
    </div>
  );

}


export default BrandDistributionView;